import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Modal from 'react-modal';

import './layout.scss';
import ContactModal from './contact-modal';
import ModalProvider, { ModalContext } from './context/Modal';

const Providers = ({ children }) => <ModalProvider>{children}</ModalProvider>;

const Layout = ({ children }) => {
  return (
    <Providers>
      <Helmet
        title="Scioto Management Group"
        meta={[
          {
            name: 'description',
            content:
              'A property management firm that goes beyond expectations to grow value and generate profit for the long term',
          },
          {
            name: 'keywords',
            content: 'property management, management, columbus, broker, firm',
          },
        ]}
      >
        <meta name="viewport" content="width=1024" />
        <html lang="en" />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:200,400,600"
          rel="stylesheet"
        />
      </Helmet>
      <ContactModal />
      <ModalContext.Consumer>
        {({ isModalOpen }) => (
          <div className={`container${isModalOpen ? ' modalOpen' : ''}`}>
            {children}
          </div>
        )}
      </ModalContext.Consumer>
    </Providers>
  );
};

export default Layout;
