import React, { useState } from 'react';

export const ModalContext = React.createContext({
  isModalOpen: false,
  setModalState: () => {},
});

export default class ModalProvider extends React.Component {
  state = {
    isModalOpen: false,
  };

  setModalState = modalState => {
    this.setState({
      isModalOpen: modalState,
    });
  };

  render() {
    return (
      <ModalContext.Provider
        value={{
          isModalOpen: this.state.isModalOpen,
          setModalState: this.setModalState,
        }}
      >
        {this.props.children}
      </ModalContext.Provider>
    );
  }
}
