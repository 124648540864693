import React, { useState } from 'react';
import Modal from 'react-modal';
import { ModalContext } from './context/Modal';
import { navigate } from 'gatsby';
import './contact-modal.scss';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    width: '800px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default function ContactModal() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = setModalState => e => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({
        'form-name': 'contact-us',
        ...this.state,
      }),
    })
      .then(response => {
        console.log('====================================');
        console.log(`${JSON.stringify(response, null, 2)}`);
        console.log('====================================');
        navigate('/success');
      })
      .catch(error => {
        console.log('====================================');
        console.log(`error in submiting the form data:${error}`);
        console.log('====================================');
      });
  };

  return (
    <ModalContext.Consumer>
      {({ isModalOpen, setModalState }) => {
        if (isModalOpen) {
          modalStyles.content.display = 'block';
          modalStyles.overlay.display = 'block';
        } else {
          modalStyles.content.display = 'none';
          modalStyles.overlay.display = 'none';
        }
        return (
          <Modal style={modalStyles} isOpen={true}>
            <button
              className="modal-close"
              onClick={() => setModalState(false)}
            >
              X
            </button>
            <div className="modal-container">
              <form
                className="modal-container"
                name="contact-us"
                method="post"
                action="/success"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <p class="hidden">
                  <label>
                    Don’t fill this out if you're human:{' '}
                    <input name="bot-field" />
                  </label>
                </p>
                <h1>Contact Us</h1>
                <div className="underline" />
                <input
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  name="name"
                  placeholder="Your name"
                />
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  name="email"
                  placeholder="Email"
                />
                <input
                  type="text"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  name="phone"
                  placeholder="Phone Number"
                />
                <input
                  type="text"
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                  name="subject"
                  placeholder="Subject"
                />
                <textarea
                  type="text"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  name="message"
                  placeholder="Message"
                />
                <button type="submit" className="contact-button">
                  Send Message
                </button>
              </form>
            </div>
          </Modal>
        );
      }}
    </ModalContext.Consumer>
  );
}
